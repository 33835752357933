// methods
import {
  getClassLessonListNew,
  getClassLessonList,
  getClassListData,
  delClassLesson,
  getClassLessonAdd,
  getTeacherListByUserName,
} from '@/api/basic';

// components
import { Button, Table, TableColumn, Select, Option } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import teachDialog from './components/teachDialog';

let gradeMap = new Map(); // Map存年级班级数据

export default {
  name: 'teach',
  props: {},
  data() {
    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      gradeId: '-1', // 年级ID
      gradeList: [
        {
          gradeId: '-1',
          gradeName: '全部',
        },
      ], // 年级数据
      classId: '-1', // 班级ID
      classList: [
        {
          classId: '-1',
          className: '全部',
        },
      ],
      lessonList: [], // 学科数据
      teacherList: [], // 教师数据
      isEdit: false, // 是否编辑模式
      visible: false,

      formData: {
        gradeId: '',
        classId: '',
        subjectId: '',
        teacherId: '',
      },
      gradeListDialog: [], // 弹窗年级列表
      classListDialog: [], // 弹窗班级列表
    };
  },
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    basicHeader,
    qlPagination,
    teachDialog,
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    notAllowOperate() {
      return this.userInfo.thirdpartySchoolProjectCode !== '';
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    gradeMap = null;
    gradeMap = new Map();
  },
  methods: {
    init() {
      gradeMap.set('-1', []);
      this.getClassLessonListNew();
    },
    getClassLessonListNew() {
      let { pageIndex, pageSize } = this.listData;
      getClassLessonListNew({
        pageIndex,
        pageSize,
      }).then(
        (res) => {
          let {
            classLessonPage: { recordCount, data },
            gradeList,
          } = res.result;
          this.listData.total = recordCount;
          this.gradeList = [...this.gradeList, ...gradeList];
          this.gradeListDialog = gradeList;
          this.listData.list = data || [];
        },
        (err) => console.log(err)
      );
    },

    getClassLessonList(reset = false) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) pageIndex = this.listData.pageIndex = 1;

      this.loading = true;

      getClassLessonList({
        pageIndex,
        pageSize,
        gradeId: this.gradeId === '-1' ? undefined : this.gradeId,
        classId: this.classId === '-1' ? undefined : this.classId,
      })
        .then(
          (res) => {
            let { recordCount, data } = res.result;
            this.listData.total = recordCount;
            this.listData.list = data || [];
          },
          (err) => console.log(err)
        )
        .finally(() => {
          this.loading = false;
        });
    },

    // 年级改变
    async handleGradeChange(id) {
      const classList = await this.getClassListData(id);
      this.classList = [
        {
          classId: '-1',
          className: '全部',
        },
        ...classList,
      ];
      this.classId = '-1';
      this.getClassLessonList(true);
    },

    // 弹窗年级改变
    async handleDialogGradeChange(id) {
      const classList = await this.getClassListData(id);
      this.classListDialog = classList;
      this.formData.classId = '';
    },

    async getClassListData(id) {
      let classList = [];
      if (gradeMap.has(id)) {
        classList = gradeMap.get(id);
      } else {
        const res = await getClassListData({
          gradeId: id,
        });
        const data = res.result || [];
        gradeMap.set(id, data);
        classList = data;
      }
      return classList;
    },

    // 教师列表
    async getTeacherListByUserName() {
      if (!this.teacherList || this.teacherList.length === 0) {
        return getTeacherListByUserName({}).then((res) => {
          const data = res.result || [];
          this.teacherList = data;
        });
      }
    },

    // 班级改变
    handleClassChange() {
      this.getClassLessonList(true);
    },

    // 新增任教
    async handleAdd() {
      if (this.notAllowOperate) return;

      await this.getClassLessonAdd();
      await this.getTeacherListByUserName();
      this.classListDialog = [];
      this.formData = {
        gradeId: '',
        classId: '',
        subjectId: '',
        teacherId: '',
        classLessonId: undefined,
      };
      this.isEdit = false;
      this.visible = true;
    },

    // 编辑任教
    async handleEdit(item) {
      if (this.notAllowOperate) return;

      await this.getClassLessonAdd();
      await this.handleDialogGradeChange(item.classes.gradeId);
      await this.getTeacherListByUserName();
      this.formData = {
        gradeId: null,
        classId: item.classId,
        subjectId: item.lesson.lessonId,
        teacherId: item.userId,
        teacherName: item.teacher.userName,
        classLessonId: item.classLessonId,
      };

      this.isEdit = true;
      this.visible = true;
    },

    async getClassLessonAdd() {
      if (this.lessonList && this.lessonList.length === 0) {
        const res = await getClassLessonAdd({});
        const { lessonList } = res.result;
        this.lessonList = lessonList;
      }
    },

    handleDel(item) {
      if (this.notAllowOperate) return;

      this.$msgbox
        .confirm('你确定删除任教信息吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          delClassLesson({
            classLessonId: item.classLessonId,
          }).then((res) => {
            if (res && res.result === 0) {
              let idx = this.listData.pageIndex;
              if (idx > 1 && this.listData.list && this.listData.list.length === 1) {
                idx--;
              }
              this.handleCurrentChange(idx);
            }
          });
        });
    },

    handleSuccess() {
      this.visible = false;
      this.getClassLessonList(!this.isEdit);
    },

    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getClassLessonList(true);
    },
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getClassLessonList();
    },

    // 批量录入
    handleImport() {
      if (this.notAllowOperate) return;

      this.$batchEntry({
        type: 'teach',
        callback: () => {
          this.gradeId = '-1';
          this.classId = '-1';
          this.getClassLessonList(true);
        },
      });
    },

    // 批量导出
    handleExport() {
      this.$batchExport({
        type: 'teach',
      });
    },
  },
};
