<template>
  <el-dialog
    custom-class="ql-dialog teachDialog"
    :title="isEdit ? '编辑任教' : '新增任教'"
    :visible.sync="visible"
    width="580px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="85px">
      <el-form-item label="年级" prop="gradeId" v-if="!isEdit" size="small">
        <el-select
          v-model="formData.gradeId"
          placeholder="请选择年级"
          @change="handleDialogGradeChange"
          style="width: 100%"
        >
          <el-option
            v-for="item in gradeListDialog"
            :key="item.gradeId"
            :label="item.gradeName"
            :value="item.gradeId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级" prop="classId" size="small">
        <el-select v-model="formData.classId" placeholder="请选择班级" style="width: 100%" :disabled="isEdit">
          <el-option
            v-for="item in classListDialog"
            :key="item.classId"
            :label="item.className"
            :value="item.classId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学科" prop="subjectId" size="small">
        <el-select v-model="formData.subjectId" placeholder="请选择年级学科" style="width: 100%" :disabled="isEdit">
          <el-option
            v-for="item in lessonList"
            :key="item.lessonId"
            :label="item.lessonName"
            :value="item.lessonId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="教师名字" prop="teacherId" size="small">
        <el-select
          v-model="formData.teacherId"
          remote
          :remote-method="remoteMethod"
          filterable
          placeholder="请输入教师姓名关键字检索"
          style="width: 100%"
        >
          <el-option
            v-for="item in options"
            :key="item.userId"
            :label="item.userName + ' ' + (item.teacherNo || '')"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleAdd" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Input, Form, FormItem, Select, Option } from 'element-ui';
import { saveClassLesson, getTeacherListByUserName } from '@/api/basic';

export default {
  name: 'TeachDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    gradeListDialog: Array,
    classListDialog: Array,
    lessonList: Array,
    teacherList: Array,
  },
  data() {
    const validateGradeId = (rule, value, callback) => {
      if (value !== null && !value) {
        callback(new Error('请选择年级'));
        return;
      }
      callback();
    };
    const validateClassId = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择班级'));
        return;
      }
      callback();
    };
    const validateSubjectId = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择学科'));
        return;
      }
      callback();
    };
    const validateTeacherId = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择教师姓名'));
        return;
      }
      callback();
    };
    return {
      formData: {
        gradeId: '',
        classId: '',
        subjectId: '',
        teacherId: '',
      },
      options: [],
      rules: {
        gradeId: [
          {
            required: true,
            validator: validateGradeId,
            trigger: 'blur',
          },
        ],
        classId: [
          {
            required: true,
            validator: validateClassId,
            trigger: 'blur',
          },
        ],
        subjectId: [
          {
            required: true,
            validator: validateSubjectId,
            trigger: 'blur',
          },
        ],
        teacherId: [
          {
            required: true,
            validator: validateTeacherId,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.remoteMethod();
        this.formData = this.dialogData;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  methods: {
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveClassLesson({
            'classes.gradeId': this.formData.gradeId,
            classId: this.formData.classId,
            lessonId: this.formData.subjectId,
            userId: this.formData.teacherId,
            'classes.classId': this.formData.classId,
            classLessonId: this.formData.classLessonId,
          }).then((res) => {
            if (res && res.result === 0) {
              this.$emit('success', this.formData);
            }
          });
        } else {
          return false;
        }
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },

    handleDialogGradeChange(id) {
      this.$emit('gradeChange', id);
    },

    remoteMethod(query) {
      query = query ? query.replace(/ /g, '') : '';
      if (query) {
        this.loading = true;
        getTeacherListByUserName({
          userName: query,
        }).then((res) => {
          this.loading = false;
          this.options = res.result;
        });
      } else {
        this.options = this.teacherList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.teachDialog {
}
</style>
